import {Component, OnInit, ViewChild, Input, OnChanges, SimpleChange} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';

/*Providers*/
import {IrisPatientService, Patient} from '../../providers/iris-patient.service';


@Component({
  selector: 'app-admission-search-table',
  templateUrl: './admission-search-table.component.html',
  styleUrls: ['./admission-search-table.component.css']
})
export class AdmissionSearchTableComponent implements OnInit, OnChanges {

  @Input() patientList: Patient[];
  @Input() dialogOpen: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['Gender', 'Age', 'Hypertension', 'Heart Disease', 'Ever Married', 'Work Type', 'Residence Type', 'Average Glucose Level', 'BMI', 'Smoking Status', 'probability_stroke'];
  dataSource: MatTableDataSource<Patient>;

  constructor(private IPS: IrisPatientService) {
    
    this.IPS.getAdmissions().subscribe( res =>{
      try{
        console.log(res)
        if(res && res.requestResult && res.patients){

          var patients = res.patients.map(this.IPS.PatientBuilder);
          var patient = patients[0];
          console.log(patient);
          if(res.requestResult.status === 'OK'){
            this.patientList = patients;
            this.dataSource = new MatTableDataSource(this.patientList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      }catch(err){
        console.log('error transforming patient data: ', err);
      }
    },
    (err) => {console.log('Error Loading Patient List: ', err);});

  }

  ngOnInit() {

  }
  
  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    let log: string[] = [];
    for (let propName in changes) {
      let changedProp = changes[propName];
      let to = JSON.stringify(changedProp.currentValue);
      if (changedProp.isFirstChange()) {
        log.push(`Initial value of ${propName} set to ${to}`);
      } else {
        if(propName === 'patientList'){
          this.dataSource = new MatTableDataSource(changedProp.currentValue);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          log.push(to);
        }else{
          console.log("Something Else Changed");
        }
      }
    }
    console.log(log);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectRow(row){
    console.log("clicked Row: ", row);
    this.dialogOpen(row);
  }

  
}
