import { Component, OnInit } from '@angular/core';

/*Providers*/
import {IrisPatientService, Patient} from '../../providers/iris-patient.service';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-admission-root',
  templateUrl: './admission-root.component.html',
  styleUrls: ['./admission-root.component.css']
})
export class AdmissionRootComponent implements OnInit {
  ngOnInit() {
    
  }
}
