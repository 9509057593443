import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import DemoConfig from '../config/demo_config';

export class Patient {

  gender: string;
  age: number;
  hypertension: boolean;
  heart_disease: boolean;
  ever_married: boolean;
  work_type: string;
  Residence_type: string;
  avg_glucose_level: number;
  bmi: number;
  smoking_status: string;
  probability_stroke: number;

  prettyPrintDate(dateForConversion: Date): string{

    let output: string = "";

    const addZero = (num) => {
      let output = num;
      if(num){
        if(num.toString().length === 1){
          output = "0" + num;
        }
      }
      return output;
    }

    if(dateForConversion){
      output = dateForConversion.getFullYear() + "-" +
      addZero((dateForConversion.getMonth() + 1)) + "-" +
      addZero(dateForConversion.getDate());
    }

    return output;
  }

  constructor(
    gender: string,
  age: number,
  hypertension: boolean,
  heart_disease: boolean,
  ever_married: boolean,
  work_type: string,
  Residence_type: string,
  avg_glucose_level: number,
  bmi: number,
  smoking_status: string,
  probability_stroke: number,
  ){
    this.gender = gender;
    this.age = age;
    this.hypertension = hypertension;
    this.heart_disease = heart_disease;
    this.ever_married = ever_married;
    this.work_type = work_type;
    this.Residence_type = Residence_type;
    this.avg_glucose_level = avg_glucose_level;
    this.bmi = bmi;
    this.smoking_status = smoking_status;
    this.probability_stroke = probability_stroke;
  }
}


@Injectable({
  providedIn: 'root'
})
export class IrisPatientService {


  constructor(private http: HttpClient) { }

  PatientBuilder(conversionObj: any): Patient{
    return new Patient(
      conversionObj.gender,
      conversionObj.age,
      conversionObj.hypertension,
      conversionObj.heart_disease,
      conversionObj.ever_married,
      conversionObj.work_type,
      conversionObj.Residence_type,
      conversionObj.avg_glucose_level,
      conversionObj.bmi,
      conversionObj.smoking_status,
      conversionObj.probability_stroke
    );
  }

  getEmpyPatient(): Patient {
    return new Patient("", 0, false, false,false, "", "", 0, 0, "", 0);
  }

  getAuthHeader(): HttpHeaders{
    const header = new HttpHeaders()
            .set("Authorization", "Basic " + btoa(DemoConfig.CREDENTIALS.userName + ":" + DemoConfig.CREDENTIALS.password));

    return header;
  }



  getAdmissions(): Observable<any>{

    const header = this.getAuthHeader();

    return this.http.get(
      DemoConfig.URL.probabilitiesList,
      {
        headers: header
      }
    )
  }


}
