import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContentContainerComponent } from './content-container/content-container.component';

import {CustomMaterialModule} from '../custom-material/custom-material.module';
import { AdmissionRootComponent } from './admission-root/admission-root.component';
import { AdmissionSearchTableComponent } from './admission-search-table/admission-search-table.component';
/*Providers*/
import {IrisPatientService} from '../providers/iris-patient.service';

/*Uniform angualr spinners*/
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxChartsModule } from '@swimlane/ngx-charts';


@NgModule({
  declarations: [
    ContentContainerComponent,
    AdmissionRootComponent,
    AdmissionSearchTableComponent,
  ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    FormsModule,
    NgxSpinnerModule,
    NgxChartsModule
   
  ],
  exports: [
    ContentContainerComponent,
    AdmissionRootComponent
  ],
  providers: [IrisPatientService]
 
})
export class DemoRootModule { }
